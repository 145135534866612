body {
  font: 14px "Century Gothic", Futura, sans-serif;
  margin: 20px;
  --square: 20vmin;
}

ol, ul {
  padding-left: 30px;
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
  width: calc(var(--square)*3);
}

#status {
  background: rgb(251, 178, 41);
  color: #fefefe;
  font-size: 20px;
  padding: 10px;
  text-align: center;
}

.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: var(--square);
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: var(--square);
  cursor: pointer;
}

.square img {
  width: calc(var(--square) - 4px);
  height: calc(var(--square) - 4px);
  border-radius: 20%;
}

/* .square img[win] {
  opacity: 0.2;
} */

.square:focus {
  outline: none;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.game {
  display: flex;
  flex-direction: row;
}

.game-info {
  margin-left: 10px;
  width: calc(var(--square)*2);
}

.game-info li{
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.control {
  background-color: white;
  border: 0.5px dashed;
  border-radius: 20%;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  padding: 5px;
  margin: 5px;
}

#root {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#head {
  margin-bottom: 2em;
  }
